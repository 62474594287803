export function vues(){
	// USE VUE
    let faq = {
		data: function () {
			return {
				flag: false
			}
		},
		methods: {
			toggle : function(){
				this.flag = !this.flag;
			}
		},
		props: ['question' , 'answer'],
		template: 
		`<dl :class="{ active: flag }">
            <dt @click="toggle">{{ question }}<i class="fas fa-angle-down"></i></dt>
            <dd v-show="flag" v-html="answer"></dd>
        </dl>`
	};
		
	const wrap_vue = new Vue({
		el: '#app',
		data: {
            sitename: 'Dogas',
            privacy_toggle: false,
			contact_flag: true,
			send_flag: false,
            entry: {
                company: '',
                name: '',
                email: '',
                context: '',
            }
        },
		methods: {
			validate: function(){
                this.contact_flag = true;
				if(!this.entry.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
					this.contact_flag = false;
                }else if(this.entry.company == '' | this.entry.name == '' | this.entry.email == '' | this.entry.context == ''){
					this.contact_flag = false;
                }
                if(this.contact_flag){
                    this.send_flag = true;
                }
			},
			mailsend: function(){
                if(this.send_flag){
                    const post_url = '/mail/post.php';
                    const $_this = this;
                    axios.post(post_url , $_this.entry)
                    .then(response => {
                        if(response.data == 'success'){
                            location.href = "/send/";
                        }
                    }).catch(error => {
                        console.log(error);
                    });
                }else{
                    this.contact_flag = false;
                }
			},
		},
		components: {
			'faq' : faq,
		}
	});
}